<template>
  <div class="">
    <div class="has-background-white mb-3">
      <b-navbar class="has-background-white container has-shadow">
        <template #start class="">
          <b-navbar-item @click="$router.push('/')" v-if="$api.user.is_verified"> {{
            $t('menu.courses')
          }}
          </b-navbar-item>
          <b-navbar-item @click="$router.push('/users')" v-if="$api.canEdit() || $api.user.is_inspector">
            {{ $t('menu.users') }}
          </b-navbar-item>
          <b-navbar-item @click="$router.push('/questions')" v-if="$api.canEdit() || $api.user.is_inspector">
            {{ $t('menu.questions') }}
          </b-navbar-item>
          <b-navbar-item @click="$router.push('/quiz')" v-if="$api.user.is_verified"> {{
            $t('menu.exercises')
          }}
          </b-navbar-item>
          <b-navbar-item @click="$router.push('/learning')" v-if="$api.user.is_verified || $api.user.is_inspector"> {{
            $t('menu.learning')
          }}
          </b-navbar-item>
          <b-navbar-item @click="$router.push('/simulators/VOR')"
            v-if="($api.user.is_verified && simulatorsEnabled) || $api.user.is_inspector"> {{
              $t('menu.simulators')
            }}
          </b-navbar-item>
          <b-navbar-item @click="$router.push('/settings')" v-if="$api.canEdit()"> {{
            $t('menu.settings')
          }}
          </b-navbar-item>
        </template>
        <template #end>
          <b-navbar-item tag="div">
            <div class="buttons">
              <a class="button is-primary" @click="$router.push('/me')">
                <strong>{{ user.first_name }} {{ user.last_name }}</strong>
              </a>
              <b-select v-model="$i18n.locale" style="margin-top: -8px; margin-right: 8px;">
                <option v-for="lang in ['pl', 'en']" :key="lang" :value="lang">
                  <span v-if="lang === 'pl'">Polski</span>
                  <span v-if="lang === 'en'">English</span>
                </option>
              </b-select>
              <button class="button is-light" @click="$api.logout">
                {{ $t('menu.logout') }}
              </button>
            </div>
          </b-navbar-item>
        </template>
      </b-navbar>
    </div>

    <div class="container" style="min-height: 80vh">
      <div class="columns">
        <div class="column is-one-quarter" v-if="['Course', 'Chapter', 'Lesson'].includes($route.name)">
          <div class="card is-shadowless is-bordered" style="border: 2px dashed rgba(0, 0, 0, 0.13)">
            <div class="card-content" v-if="$api.sidebar.course != null">
              <b-menu :key="counter">
                <b-menu-list :label="$api.sidebar.course.name">
                  <b-menu-item :label="$t('sidebar.courseInformation')" @click="$router.push('/courses/' +
                    $route.params.course_id)"></b-menu-item>
                  <draggable v-model="chapters" @end="save()">
                    <b-menu-item :label="index + 1 + '. ' + chapter.name" v-for="(chapter, index) in chapters"
                      :key="chapter.id" @click="
                        $router.push(
                          '/courses/' +
                          $route.params.course_id +
                          '/chapters/' +
                          chapter.id
                        )
                        " :expanded.sync="$api.expanded[chapter.id]">
                      <draggable v-model="chapter.lessons" @end="save(chapter)">
                        <b-menu-item
                          :label="lesson.name + (lesson.type === 'quiz' ? ' 📝' : '') + (lesson.type === 'pdf_file' ? '📄' : '')"
                          v-for="lesson in chapter.lessons" :key="lesson.id"
                          :active.sync="$api.activated['lesson_' + lesson.id]" @click="
                            $router.push(
                              '/courses/' +
                              $route.params.course_id +
                              '/chapters/' +
                              chapter.id +
                              '/lessons/' +
                              lesson.id
                            )
                            "></b-menu-item>
                      </draggable>
                      <div class="link has-text-info" @click="createLesson(chapter)"
                        style="margin-left: 12px; margin-top: 4px" v-if="$api.canEdit()">
                        {{ $t('sidebar.addLesson') }}
                      </div>
                    </b-menu-item>
                  </draggable>
                  <div class="link has-text-info" @click="createChapter" style="margin-left: 12px; margin-top: 4px"
                    v-if="$api.canEdit()">
                    {{ $t('sidebar.addChapter') }}
                  </div>
                </b-menu-list>

              </b-menu>
            </div>
          </div>
          <div class="card is-shadowless is-bordered mt-5" style="border: 2px dashed rgba(0, 0, 0, 0.13)">
            <div class="card-content">
              {{ $t('sidebar.learnTime') }}: <b>{{ courseTimeString }}</b><br />
            </div>
          </div>
        </div>
        <div class="column">
          <slot></slot>
        </div>
      </div>
    </div>
    <!--    <div style="text-align: center; position: fixed; bottom: 4px; left: auto; right: auto; background-color: #F7F7F7">-->
    <!--      <div style="margin-left: auto; margin-right: auto; text-align: center; width: 100vw;">-->
    <!--        <b>{{ settings.owner }}</b>-->
    <!--      </div>-->
    <!--    </div>-->
    <div
      style="text-align: center; bottom: 4px; left: auto; right: auto; background-color: #F7F7F7; margin-top: 8px; margin-bottom: 4px;">
      <div style="margin-left: auto; margin-right: auto; text-align: center; width: 100vw;">
        <b>{{ settings.owner }}</b>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "HomeWrapper",
  components: {
    draggable,
  },
  computed: {
    courseTimeString() {
      const h = Math.floor(this.courseTime / 3600);
      const m = Math.floor(this.courseTime % 3600 / 60);
      const s = Math.floor(this.courseTime % 3600 % 60);
      return `${h}h. ${m} min. ${s} s.`;
    }
  },
  data() {
    return {
      user: {},
      expanded: [],
      counter: 0,
      chapters: [],
      truth: true,
      courseTime: 0,
      courses: [],
      settings: {},
      assignedCoursesIds: [],
      simulatorsEnabled: false
    };
  },
  async mounted() {
    this.user = await this.$api.getProfile();
    this.settings = await this.$api.loadSettings();
    if (this.$api.sidebar.chapters.length > 0) {
      this.chapters = this.$api.sidebar.chapters;
    } else {
      if (this.$route.params.course_id)
        this.chapters = await this.$api.getChapters(
          this.$route.params.course_id
        );
    }
    const self = this;
    this.courses = (await this.$api.getCourses()).data;
    this.assignedCoursesIds = JSON.parse(this.user.assigned_courses.length > 0 ? this.user.assigned_courses : '[]').filter(x => x[1] === 'on').map(x => x[0]);
    this.simulatorsEnabled = this.courses.filter(x => this.assignedCoursesIds.includes(x.id)).some(x => x.simulators_enabled.toString() === '1');
    self.courseTime = self.$api.courseTime;
    setInterval(() => {
      self.courseTime = self.$api.courseTime;
    }, 1000);
    this.$forceUpdate();
  },
  methods: {
    async createChapter() {
      const chapter = await this.$api.createChapter(
        this.$route.params.course_id,
        {
          name: "Moduł bez tytułu",
        }
      );
      this.$forceUpdate();
      this.$router.push(
        "/courses/" + this.$route.params.course_id + "/chapters/" + chapter.id
      );
    },
    async createLesson(chapter) {
      const lesson = await this.$api.createLesson(
        this.$route.params.course_id,
        chapter.id,
        {
          name: "Lekcja bez tytułu",
          sequence_number: chapter.lessons.length,
          type: "wysiwyg",
        }
      );
      this.chapters = await this.$api.getChapters(this.$route.params.course_id);
      this.$router.push(
        "/courses/" +
        this.$route.params.course_id +
        "/chapters/" +
        chapter.id +
        "/lessons/" +
        lesson.id
      );
    },
    async save(chapter = null) {
      this.$api.sidebar.chapters = this.chapters;
      let counter = 0;
      if (chapter != null) {
        for (let lesson of chapter.lessons) {
          lesson.sequence_number = counter;
          counter++;
          this.$api.editLesson(
            this.$route.params.course_id,
            chapter.id,
            lesson
          );
        }
      } else {
        for (let chapter of this.chapters) {
          chapter.sequence_number = counter;
          counter++;
          this.$api.editChapter(this.$route.params.course_id, chapter);
        }
      }
    },
  },
};
</script>

<style>
.link {
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

/* ul.menu-list > div > li > ul {
  display: block !important;
}
.slide-enter-active, .slide-leave-active {
  transition: 0ms !important;
  -webkit-transition: 0ms !important;
} */
</style>

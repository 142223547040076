<template>
  <section id="app" class="hero is-fullwidth is-fullheight">
    <HomeWrapper v-if="!$route.fullPath.includes('login') && !$route.fullPath.includes('register')">
      <router-view :key="$route.fullPath"/>
    </HomeWrapper>
    <router-view v-else :key="$route.fullPath"/>
<!--    <div style="width: 100%; text-align: center; margin-bottom: 4px;">-->
<!--      <small>{{$t('writtenBy')}} <a target="_blank" href="https://github.com/matisiekpl">Mateusz Woźniak</a> i Patryk Boczar</small>-->
<!--    </div>-->
  </section>
</template>

<style>
#app {
  background: rgba(0, 0, 0, 0.03);;
}
</style>
<script>
import HomeWrapper from "@/components/HomeWrapper.vue";

export default {
  components: {HomeWrapper}
}
</script>
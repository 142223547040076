module.exports = {
    en: {
        notVerified: 'Your account is not verified yet. Come back here later.',
        writtenBy: 'Website developed by',
        languages: {
            polish: 'Polish',
            english: 'English',
        },
        menu: {
            courses: 'Courses',
            users: 'Users',
            questions: 'Question database',
            exercises: 'Simulate exam',
            learning: 'Learning',
            loggedAs: 'Logged as ',
            logout: 'Logout',
            settings: 'Settings',
            simulators: 'Simulators'
        },
        sidebar: {
            courseInformation: 'Course details',
            learnTime: 'Learning time',
            addChapter: 'Create chapter',
            addLesson: 'Create lesson'
        },
        courses: {
            quizCategory: 'Question categories',
            addNewCourse: 'Add new course',
            settings: 'Settings',
            chooseIcon: 'Choose icon',
            name: 'Name',
            destroy: 'Delete',
            save: 'Save',
            protocolDisabled: 'Protocol disabled',
            pplaProtocol: 'PPL(A) protocol',
            kwtProtocol: 'KWT protocol',
        },
        chapter: {
            chapterName: 'Chapter name',
            edit: 'Edit',
            save: 'Save',
            destroy: 'Delete',
            confirmDestroy: 'Are you sure you want to delete this chapter?',
            cancel: 'Cancel'
        },
        users: {
            notVerified: 'Not verified',
            search: 'Search',
            addNewUser: 'Add new user',
            firstName: 'First name',
            lastName: 'Last name',
            email: 'Email',
            language: 'Language',
            organisation: 'Organisation',
            organisations: 'Organisations',
            isVerified: 'Is verified?',
            isEditor: 'Is editor?',
            isAdmin: 'Is admin?',
            actions: 'Actions',
            edit: 'Edit',
            destroy: 'Delete',
            cancel: 'Cancel',
            noData: 'No data',
            courses: 'Courses',
            report: 'Report',
            resetScores: 'Reset scores',
            password: 'Password',
            yes: 'Yes',
            no: 'No',
            save: 'Save'
        },
        register: {
            firstName: 'First name',
            lastName: 'Last name',
            email: 'Email',
            password: 'Password',
            register: 'Sign up',
            login: 'Sign in'
        },
        login: {
            email: 'Email',
            password: 'Password',
            createAccount: 'Create account',
            login: 'Sign in'
        },
        quiz: {
            categories: 'Categories',
            questionCount: 'Questions count',
            startQuiz: 'Begin test',
            question: 'Question',
            nextQuestion: 'Next question',
            previousQuestion: 'Previous question',
            finishQuiz: 'Finish test',
            restartQuiz: 'Restart test',
            result: 'Result',
            explanation: 'Explanation',
            noExplanation: 'No explanation'
        },
        questions: {
            addNewQuestion: 'Add new question',
            category: 'Category',
            code: 'Code',
            language: 'Language',
            question: 'Question',
            actions: 'Actions',
            edit: 'Edit',
            save: 'Save',
            destroy: 'Delete',
            cancel: 'Cancel',
            noData: 'No data',
            correctAnswer: 'Correct answer',
            answerA: 'Answer A',
            answerB: 'Answer B',
            answerC: 'Answer C',
            answerD: 'Answer D',
            content: 'Question content'
        },
        learning: {
            learning: 'Learning',
            question: 'Question',
            next: 'Next',
            previous: 'Previous',
            check: 'Check',
        },
        lesson: {
            scoreIsSaved: 'Wynik i czas tego podejścia jest zapisywany w protokole egzaminowym. Liczba podejść jest ograniczona.',
            lessonName: 'Lesson name',
            actions: 'Actions',
            edit: 'Edit',
            destroy: 'Delete',
            cancel: 'Cancel',
            confirmDestroy: 'Are you sure you want to delete this lesson?',
            nextQuestion: 'Next question',
            previousQuestion: 'Previous question',
            question: 'Question',
            nextPage: 'Next page',
            previousPage: 'Previous page',
            restartQuiz: 'Restart test',
            choosePDF: 'Choose or drop PDF file here',
            uploading: 'Uploading...',
            downloadOriginal: 'Download original PDF',
            categories: 'Categories',
            questionCount: 'Questions count',
            protocolLabel: 'Subject name in protocol',
            timeLimit: 'Time limit in minutes',
            startQuiz: 'Begin test',
            finishQuiz: 'Finish test',
            explanation: 'Explanation',
            noExplanation: 'No explanation',
            save: 'Save',
            pdfFile: 'PDF',
            paginatedPdfFile: 'PDF (with pagination)',
            classicalText: 'Classical text',
            quiz: 'Quiz',
            saveToProtocol: 'Save to protocol',
            protocolDisabled: 'Protocol disabled',
            tooManyAttempts: 'Reached attempts limit'
        }
    },
    pl: {
        notVerified: 'Twoje konto nie zostało jeszcze zweryfikowane. Wróć tutaj później.',
        writtenBy: 'Strona wykonana przez',
        languages: {
            polish: 'Polski',
            english: 'Angielski',
        },
        menu: {
            courses: 'Szkolenia',
            users: 'Użytkownicy',
            questions: 'Baza pytań',
            exercises: 'Symulator testu',
            learning: 'Nauka',
            loggedAs: 'Zalogowano jako ',
            logout: 'Wyloguj',
            settings: 'Ustawienia',
            simulators: 'Symulatory'
        },
        sidebar: {
            courseInformation: 'Informacje o szkoleniu',
            learnTime: 'Czas nauki',
            addChapter: 'Dodaj moduł',
            addLesson: 'Dodaj lekcję'
        },
        courses: {
            quizCategory: 'Kategorie pytań',
            addNewCourse: 'Dodaj nowy kurs',
            settings: 'Ustawienia',
            chooseIcon: 'Wybierz ikonę',
            name: 'Nazwa',
            destroy: 'Usuń',
            save: 'Zapisz',
            protocolDisabled: 'Protokół wyłączony',
            pplaProtocol: 'Protokół PPL(A)',
            kwtProtocol: 'Protokół KWT',
        },
        chapter: {
            chapterName: 'Nazwa modułu',
            edit: 'Edytuj',
            save: 'Zapisz',
            destroy: 'Usuń',
            confirmDestroy: 'Na pewno chcesz usunąć ten moduł?',
            cancel: 'Anuluj'
        },
        users: {
            notVerified: 'Nie zweryfikowani',
            search: 'Szukaj',
            addNewUser: 'Dodaj nowego użytkownika',
            firstName: 'Imię',
            lastName: 'Nazwisko',
            email: 'Email',
            language: 'Język',
            organisation: 'Organizacja',
            organisations: 'Organizacje',
            isVerified: 'Czy zweryfikowany?',
            isEditor: 'Czy redaktor?',
            isAdmin: 'Czy admin?',
            actions: 'Akcje',
            edit: 'Edytuj',
            destroy: 'Usuń',
            cancel: 'Anuluj',
            noData: 'Brak danych',
            courses: 'Kursy',
            report: 'Protokół',
            resetScores: 'Resetuj podejścia',
            password: 'Hasło',
            yes: 'Tak',
            no: 'Nie',
            save: 'Zapisz'
        },
        register: {
            firstName: 'Imię',
            lastName: 'Nazwisko',
            email: 'Email',
            password: 'Hasło',
            register: 'Zarejestruj się',
            login: 'Zaloguj się'
        },
        login: {
            email: 'Email',
            password: 'Hasło',
            createAccount: 'Utwórz konto',
            login: 'Wejdź'
        },
        quiz: {
            categories: 'Kategorie',
            questionCount: 'Ilość pytań',
            startQuiz: 'Rozpocznij test',
            question: 'Pytanie',
            nextQuestion: 'Następne pytanie',
            previousQuestion: 'Poprzednie pytanie',
            finishQuiz: 'Zakończ test',
            restartQuiz: 'Ponów test',
            result: 'Wynik',
            explanation: 'Wytłumaczenie',
            noExplanation: 'Brak wytłumaczenia'
        },
        questions: {
            addNewQuestion: 'Dodaj nowe pytanie',
            category: 'Kategoria',
            code: 'Kod',
            language: 'Język',
            question: 'Pytanie',
            actions: 'Akcje',
            save: 'Zapisz',
            edit: 'Edytuj',
            destroy: 'Usuń',
            cancel: 'Anuluj',
            noData: 'Brak danych',
            correctAnswer: 'Poprawna odpowiedź',
            answerA: 'Odpowiedź A',
            answerB: 'Odpowiedź B',
            answerC: 'Odpowiedź C',
            answerD: 'Odpowiedź D',
            content: 'Treść pytania'
        },
        learning: {
            learning: 'Nauka',
            question: 'Pytanie',
            next: 'Dalej',
            previous: 'Wstecz',
            check: 'Sprawdź',
        },
        lesson: {
            scoreIsSaved: 'Wynik i czas tego podejścia jest zapisywany w protokole egzaminowym. Liczba podejść jest ograniczona.',
            lessonName: 'Nazwa lekcji',
            actions: 'Akcje',
            edit: 'Edytuj',
            destroy: 'Usuń',
            save: 'Zapisz',
            cancel: 'Anuluj',
            confirmDestroy: 'Na pewno chcesz usunąć tą lekcję?',
            nextQuestion: 'Następne pytanie',
            previousQuestion: 'Poprzednie pytanie',
            question: 'Pytanie',
            nextPage: 'Następna strona',
            previousPage: 'Poprzednia strona',
            restartQuiz: 'Ponów test',
            choosePDF: 'Wybierz lub upuść tutaj plik PDF',
            uploading: 'Trwa wgrywanie...',
            downloadOriginal: 'Pobierz oryginał PDF',
            categories: 'Kategorie',
            questionCount: 'Ilość pytań',
            protocolLabel: 'Nazwa przedmiotu w protokole',
            timeLimit: 'Ilość minut',
            startQuiz: 'Rozpocznij test',
            finishQuiz: 'Zakończ test',
            explanation: 'Wytłumaczenie',
            noExplanation: 'Brak wytłumaczenia',
            pdfFile: 'Plik PDF',
            paginatedPdfFile: 'Plik PDF (z paginacją)',
            classicalText: 'Tekst zwyczajny',
            quiz: 'Quiz',
            saveToProtocol: 'Zapisz do protokołu',
            protocolDisabled: 'Protokół wyłączony',
            tooManyAttempts: 'Wykorzystano wszystkie podejścia'
        }
    }
}

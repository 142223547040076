import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
    {
        path: '/home',
        name: 'Home',
        component: Home
    },
    {
        path: '/login',
        name: 'Login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import(/* webpackChunkName: "Register" */ '../views/Register.vue')
    },
    {
        path: '/users',
        name: 'Users',
        component: () => import(/* webpackChunkName: "Users" */ '../views/Users.vue')
    },
    {
        path: '/users/:user_id',
        name: 'User',
        component: () => import(/* webpackChunkName: "Users" */ '../views/User.vue')
    },
    {
        path: '/scores',
        name: 'Scores',
        component: () => import(/* webpackChunkName: "Users" */ '../views/Scores.vue')
    },
    {
        path: '/',
        name: 'Courses',
        component: () => import(/* webpackChunkName: "Courses" */ '../views/Courses.vue')
    },
    {
        path: '/courses/:course_id',
        name: 'Course',
        component: () => import(/* webpackChunkName: "Course" */ '../views/Course.vue')
    },
    {
        path: '/courses/:course_id/chapters/:chapter_id',
        name: 'Chapter',
        component: () => import(/* webpackChunkName: "Chapter" */ '../views/Chapter.vue')
    },
    {
        path: '/courses/:course_id/chapters/:chapter_id/lessons/:lesson_id',
        name: 'Lesson',
        component: () => import(/* webpackChunkName: "Lesson" */ '../views/Lesson.vue')
    },
    {
        path: '/questions',
        name: 'Questions',
        component: () => import(/* webpackChunkName: "Questions" */ '../views/Questions.vue')
    },
    {
        path: '/quiz',
        name: 'Quiz',
        component: () => import(/* webpackChunkName: "Quiz" */ '../views/Quiz.vue')
    },
    {
        path: '/learning',
        name: 'Learning',
        component: () => import(/* webpackChunkName: "Learning" */ '../views/Learning.vue')
    },
    {
        path: '/not-verified',
        name: 'NotVerified',
        component: () => import(/* webpackChunkName: "NotVerified" */ '../views/NotVerified.vue')
    },
    {
        path: '/settings',
        name: 'Settings',
        component: () => import(/* webpackChunkName: "Settings" */ '../views/Settings.vue')
    },
    {
        path: '/simulators/:sim',
        name: 'Simulators',
        component: () => import(/* webpackChunkName: "Simulators" */ '../views/Simulators.vue')
    },
    {
        path: '/me',
        name: 'Profile',
        component: () => import(/* webpackChunkName: "Simulators" */ '../views/Profile.vue')
    },
]

const router = new VueRouter({
    routes,
    mode: 'history'
})

export default router

import axios from "axios";

const config = {
    endpoint: process.env.VUE_APP_API_URL,
    asset_endpoint: process.env.VUE_APP_API_URL.replace('/api/', '')
};

axios.defaults.baseURL = config.endpoint;

export default {
    user: null,
    token: '',
    vue: null,
    config,
    sidebar: {
        course: null,
        chapters: []
    },
    expanded: {},
    activated: {},
    courseTime: 0,
    canEdit() {
        return this.user == null || this.user.is_admin || this.user.is_writer;
    },
    tracker: null,
    setupTracker(v) {
        const self = this;
        if (this.tracker != null) return;
        const sendTrackData = async () => {
            if (v.$route.path.includes('chapters')) {
                const time = (await axios.post(`courses/${v.$route.params.course_id}/time/${v.$route.params.chapter_id}`)).data;
                self.courseTime = time;
                setTimeout(() => {
                    self.courseTime++;
                }, 1000);
                setTimeout(() => {
                    self.courseTime++;
                }, 2000);
                setTimeout(() => {
                    self.courseTime++;
                }, 3000);
                setTimeout(() => {
                    self.courseTime++;
                }, 4000);
            }
        };
        this.tracker = setInterval(sendTrackData, 5000);
        sendTrackData();
    },
    async getCourseTime(v) {
        const time = (await axios.post(`courses/${v.$route.params.course_id}/time/0?dry`)).data;
        this.courseTime = time;
    },
    async getChapterTime(courseId, chapterId) {
        return (await axios.post(`courses/${courseId}/time/${chapterId}?dry`)).data;
    },
    async init(vue) {
        this.vue = vue;
        try {
            if (localStorage.getItem('token') !== null) {
                this.token = localStorage.getItem('token');
                axios.interceptors.request.use(
                    async config => {
                        config.headers = {
                            'Authorization': `Bearer ${this.token}`,
                        }
                        return config;
                    },
                    error => {
                        Promise.reject(error)
                    });
                await this.getProfile();
            } else {
                if (!window.location.href.endsWith('/login'))
                    window.location.href = '/login';

            }
        } catch (err) {
            console.log(err);
            localStorage.removeItem('token');
        }
    },
    async getProfile() {
        try {
            if (!this.user) this.user = (await axios.get('auth/me')).data;
            if (!this.user.is_verified)
                if (!window.location.href.endsWith('/not-verified'))
                    window.location.href = '/not-verified';
        } catch (err) {
            if (err.response.status === 401 && !window.location.href.endsWith('/login'))
                window.location.href = '/login';

        }
        return this.user;
    },
    async login(email, password) {
        const response = await axios.post('auth/session', {email, password});
        this.token = response.data.token;
        axios.interceptors.request.use(
            async config => {
                config.headers = {
                    'Authorization': `Bearer ${this.token}`,
                }
                return config;
            },
            error => {
                Promise.reject(error)
            });
        localStorage.setItem("token", this.token);
        await this.getProfile();
        return response.data;
    },
    async register(first_name, last_name, email, password, autologin = true) {
        const response = await axios.post('auth/user', {first_name, last_name, email, password});
        if (autologin) await this.login(email, password);
        return response.data;
    },
    async logout() {
        localStorage.removeItem('token');
        window.location.reload();
    },
    async editProfile(user) {
        return (await axios.put('auth/me', user)).data;
    },
    async getUsers() {
        return (await axios.get('users')).data;
    },
    async getUser(id) {
        return (await axios.get(`users/${id}`)).data;
    },
    async editUser(user) {
        return (await axios.put(`users/${user.id}`, user)).data;
    },
    async deleteUser(user) {
        return (await axios.delete(`users/${user.id}`, {})).data;
    },
    async getCourses() {
        return (await axios.get('courses')).data;
    },
    async createCourse(course) {
        return (await axios.post(`courses`, course)).data;
    },
    async editCourse(course) {
        return (await axios.put(`courses/${course.id}`, course)).data;
    },
    async deleteCourse(course) {
        return (await axios.delete(`courses/${course.id}`)).data;
    },
    async getCourse(id) {
        return (await axios.get(`courses/${id}`)).data;
    },
    async uploadAsset(file, extractImages = false) {
        const form = new FormData();
        form.append('asset', file);
        return (await axios.post(`uploads${extractImages ? '?extract_images' : ''}`, form)).data.url;
    },
    async getChapters(courseId) {
        const chapters = (await axios.get(`courses/${courseId}/chapters`)).data;
        chapters.sort((a, b) => a.sequence_number > b.sequence_number ? 1 : -1);
        for (const chapter of chapters) {
            chapter.lessons.sort((a, b) => a.sequence_number > b.sequence_number ? 1 : -1);
        }
        return chapters;
    },
    async getChapter(courseId, id) {
        return (await axios.get(`courses/${courseId}/chapters/${id}`)).data;
    },
    async editChapter(courseId, chapter) {
        return (await axios.put(`courses/${courseId}/chapters/${chapter.id}`, chapter)).data;
    },
    async deleteChapter(courseId, chapter) {
        return (await axios.delete(`courses/${courseId}/chapters/${chapter.id}`)).data;
    },
    async createChapter(courseId, chapter) {
        return (await axios.post(`courses/${courseId}/chapters`, chapter)).data;
    },
    async getLesson(courseId, chapterId, id) {
        return (await axios.get(`courses/${courseId}/chapters/${chapterId}/lessons/${id}`)).data;
    },
    async editLesson(courseId, chapterId, lesson) {
        return (await axios.put(`courses/${courseId}/chapters/${chapterId}/lessons/${lesson.id}`, lesson)).data;
    },
    async deleteLesson(courseId, chapterId, lesson) {
        return (await axios.delete(`courses/${courseId}/chapters/${chapterId}/lessons/${lesson.id}`)).data;
    },
    async createLesson(courseId, chapterId, lesson) {
        return (await axios.post(`courses/${courseId}/chapters/${chapterId}/lessons`, lesson)).data;
    },
    async createQuestion(question) {
        return (await axios.post(`questions`, question)).data;
    },
    async getQuestions(page = 1) {
        return (await axios.get(`questions?page=${page}`)).data;
    },
    async getQuestionCategories() {
        return (await axios.get(`questions/categories`)).data;
    },
    async getQuestionIds() {
        return (await axios.get(`questions/categories/ids`)).data;
    },
    async getQuestionsByCategories(categories, count = 10) {
        return (await axios.post(`questions/categories?count=${count}`, {categories})).data;
    },
    async getQuestion(id) {
        return (await axios.get(`questions/${id}`)).data;
    },
    async editQuestion(question) {
        return (await axios.put(`questions/${question.id}`, question)).data;
    },
    async deleteQuestion(question) {
        return (await axios.delete(`questions/${question.id}`)).data;
    },
    async saveScore(score, time, courseId, lessonId) {
        return (await axios.post(`questions/scores`, {
            lesson_id: lessonId,
            course_id: courseId,
            score, time,
        })).data;
    },
    async saveSkill(questionId, mode) {
        return (await axios.post(`questions/skills/${questionId}/${mode}`)).data;
    },
    async indexSkills() {
        return (await axios.get(`questions/skills/all`)).data;
    },
    async resetScores(userId, courseId) {
        return (await axios.delete(`questions/scores/${userId}/${courseId}`)).data;
    },
    async checkScore(courseId, lessonId) {
        return (await axios.get(`questions/scores/check/${courseId}/${lessonId}`)).data;
    },
    async loadSettings() {
        return (await axios.get(`settings`)).data;
    },
    async saveWelcomeMessage(welcome) {
        return (await axios.post(`settings/welcome`, {welcome})).data;
    },
    async saveOwnerMessage(owner) {
        return (await axios.post(`settings/owner`, {owner})).data;
    },
    async saveOwnerAddressMessage(owner_address) {
        return (await axios.post(`settings/owner_address`, {owner_address})).data;
    },
    async saveNotificationEmail(notification_email) {
        return (await axios.post(`settings/notification_email`, {notification_email})).data;
    },
    async getScores() {
        return (await axios.get('scores')).data;
    },
    async deleteScore(score) {
        return (await axios.delete(`scores/${score.id}`, {})).data;
    },
    async editScore(score) {
        return (await axios.put(`scores/${score.id}`, score)).data;
    },
}

import Vue from 'vue'
import Buefy from 'buefy'
import VueI18n from 'vue-i18n'
import App from './App.vue'
import router from './router'
import 'buefy/dist/buefy.css'
import api from "./api/api";
import messages from "@/messages";

Vue.use(VueI18n);
const i18n = new VueI18n({
    locale: 'pl',
    messages,
})

Vue.config.productionTip = false
Vue.use(Buefy)
Vue.prototype.$api = api
api.init(Vue).then(() => {
    new Vue({
        i18n,
        router,
        render: h => h(App)
    }).$mount('#app')

});

<template>
  <div class="home">
      <div class="card is-shadowless is-bordered" style="border: 2px dashed rgba(0,0,0,0.13);">
        <div class="card-content">
          <img src="../assets/xd-5.png" />
        </div>
      </div>
  </div>
</template>

<script>
import HomeWrapper from "../components/HomeWrapper";
import VuePdfEmbed from 'vue-pdf-embed'

export default {
  name: 'Home',
  components: {HomeWrapper,VuePdfEmbed},
}
</script>
